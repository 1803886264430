'use client';

import { client } from '@passwordless-id/webauthn';
import { signIn } from 'next-auth/react';
import { redirect } from 'next/navigation';
import { useActionState, useEffect, useState } from 'react';
import { z } from 'zod';

import { Button, Icon, Spacer, TextInput, notify } from '@voyage-lab/ui';

import { FormButton } from '../../components/form';
import { ERROR } from '../../constants';
import { forgetPassword, resetPassword, setupAccount } from './action';

export function ForgotPassworForm(props: { setupPageLink: string }) {
	const [state, formAction] = useActionState(forgetPassword, {
		error: '',
		message: '',
	});

	useEffect(() => {
		if (!state) return;
		if (state.error) notify.error(state.error);
		if (state.message) notify.success(state.message);
	}, [state]);

	const host = typeof window !== 'undefined' ? window.location.origin : '';

	return (
		<form action={formAction}>
			<input type="hidden" name="host" value={`${host}${props.setupPageLink}`} />
			<TextInput
				required
				name="email"
				type="email"
				fullWidth
				innerPrefix={<Icon name="Mail" color="grey-1" />}
				placeholder="Email"
			/>
			<Spacer vertical={15} />
			<FormButton>Submit</FormButton>
		</form>
	);
}

export function ResetPassworForm({ config, email }: { config: React.ReactNode; email: string }) {
	const [state, formAction] = useActionState(resetPassword, {
		error: '',
		message: '',
	});

	useEffect(() => {
		if (!state) return;
		if (state.error) notify.error(state.error);
		if (state.message) notify.success(state.message);
		if (state.data?.returnUrl) {
			redirect(state.data.returnUrl);
		}
	}, [state]);

	return (
		<form action={formAction}>
			{config}
			<TextInput
				required
				disabled
				name="email"
				type="email"
				value={email}
				fullWidth
				innerPrefix={<Icon name="Mail" color="grey-1" />}
				placeholder="Email"
			/>
			<TextInput
				required
				name="password"
				type="password"
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Password"
			/>
			<TextInput
				required
				name="confirmPassword"
				type="password"
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Confirm Password"
			/>
			<Spacer vertical={15} />
			<FormButton>Submit</FormButton>
		</form>
	);
}

export function PasskeySignInForm({ challenge }: { challenge: string }) {
	if (!client.isAvailable()) return null;
	const [isLoading, setIsLoading] = useState(false);

	async function handleClick() {
		try {
			setIsLoading(true);
			const authentication = await client.authenticate({
				challenge,
			});

			if (!authentication.response.userHandle) return notify.error(ERROR.INVALID_PASSKEY);

			const userId = atob(authentication.response.userHandle);

			if (!z.string().uuid().safeParse(userId).success) return notify.error(ERROR.INVALID_PASSKEY);

			await signIn('credentials', {
				id: userId,
				passKey: JSON.stringify(authentication),
				challenge,
			});
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			notify.error(error?.message);
		} finally {
			setIsLoading(false);
		}
	}

	return (
		<Button
			type="button"
			fullWidth={false}
			onClick={handleClick}
			isLoading={isLoading}
			disabled={isLoading}
			variant={'secondary'}
			leftIcon={{
				name: 'Fingerprint',
			}}
		>
			Passkey
		</Button>
	);
}

export function SetupAccountForm({ config, email }: { config: React.ReactNode; email: string }) {
	const [state, formAction] = useActionState(setupAccount, {
		error: '',
		message: '',
	});

	useEffect(() => {
		if (!state) return;
		if (state.error) notify.error(state.error);
		if (state.message) notify.success(state.message);
		if (state.data?.returnUrl) {
			redirect(state.data.returnUrl);
		}
	}, [state]);

	return (
		<form action={formAction}>
			{config}
			<TextInput
				required
				disabled
				name="email"
				type="email"
				value={email}
				fullWidth
				innerPrefix={<Icon name="Mail" color="grey-1" />}
				placeholder="Email"
			/>
			<TextInput
				required
				name="givenName"
				fullWidth
				innerPrefix={<Icon name="User" color="grey-1" />}
				placeholder="First Name"
			/>
			<TextInput
				required
				name="familyName"
				fullWidth
				innerPrefix={<Icon name="User" color="grey-1" />}
				placeholder="Last Name"
			/>
			<TextInput
				required
				name="password"
				type="password"
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Password"
			/>
			<TextInput
				required
				name="confirmPassword"
				type="password"
				fullWidth
				innerPrefix={<Icon name="KeyRound" color="grey-1" />}
				placeholder="Confirm Password"
			/>
			<Spacer vertical={15} />
			<FormButton>Setup</FormButton>
		</form>
	);
}
